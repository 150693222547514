import { ref, computed } from "vue";
import useDynamicColumn from "./useDynamicColumn";
import useMock from "@/utils/payment/useMock";
import { DataProps } from "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/type";
import Data from "@/views/User/ApprovalProcess/ApprovalChain/PayoutChian/mock";
import { getListFLPayoutApproval, getListDealerPayoutApproval } from "@/API/approvalChain";
const useProgramChian = () => {
  const res = ref();
  const formatList = (list: DataProps[], type: string) => {
    return list.map((item: any) => {
      // Object.keys(item).forEach((key) => {
      //   if (key.indexOf(type) > 0) {
      //     item[key.replace(type, "")] = item[key];
      //   }
      //   if (key.indexOf('User') > 0 || key.indexOf('Role') > 0) {
      //       delete item[key]
      //   }
      // });
      if (item.level) {
        Object.keys(item.level).forEach((i) => {
          item[`L${i}`] = item.level[i].map((level: any) => {
            return level[type];
          });
        });
      }
      return item;
    });
  };
  const ChainUserList = computed(() => {
    const list = res.value;
    if (list) {
      const _list = JSON.parse(JSON.stringify(list));
      return formatList(_list, "userName");
    } else {
      return [];
    }
  });
  const ChainRoleList = computed(() => {
    const list = res.value;
    if (list) {
      const _list = JSON.parse(JSON.stringify(list));
      return formatList(_list, "roleName");
    } else {
      return [];
    }
  });
  const column = ref();
  const bu = ref([])
  const activeKey = ref('DEALER')
  const getChianList = () => {
    // let bus: string[] = []
    // if (bu.value) {
    //   bus = [bu.value]
    // }
    if (activeKey.value === 'DEALER') {
      getListDealerPayoutApproval({
        bus: bu.value
      }).then((data) => {
        res.value = data;
        column.value = useDynamicColumn<DataProps>(res.value, true);
      });
    } else {
      getListFLPayoutApproval({
        bus: bu.value
      }).then((data) => {
        res.value = data;
        column.value = useDynamicColumn<DataProps>(res.value, true);
      });
    }
    
    // useMock<DataProps>(Data).then((data) => {
    //   res.value = data;
    //   column.value = useDynamicColumn<DataProps>(res.value?.data?.data, true);
    // });
  };
  return {
    getChianList,
    ChainUserList,
    ChainRoleList,
    column,
    activeKey,
    bu
  };
};
export default useProgramChian;
